<template>
	<div>
		<v-app-bar app elevate-on-scroll>
			<v-toolbar-title class="cursor-pointer" @click="$router.push('/')">
				<v-img :src="require('@/assets/logo_transparent_big.png')" width="148"></v-img>
			</v-toolbar-title>

			<v-spacer></v-spacer>

			<!-- Desktop Layout -->
			<template v-if="!isTablet">
				<!-- Routes -->
				<v-toolbar-items>
					<v-btn v-for="route in routes" :key="route.id" text link :to="route">{{ $t(`routes.${route}`) }}</v-btn>
				</v-toolbar-items>

				<v-spacer></v-spacer>

				<!-- Locale -->
				<v-menu offset-y>
					<template v-slot:activator="{ on, attrs }">
						<v-btn text class="text-capitalize" v-bind="attrs" v-on="on"> {{ locale }} <v-icon>mdi-chevron-down</v-icon> </v-btn>
					</template>
					<v-list>
						<v-list-item v-for="item in locales" :key="item.id" @click="changeLocale(item.value)">
							<v-list-item-title>{{ item.text }}</v-list-item-title>
						</v-list-item>
					</v-list>
				</v-menu>
			</template>
			<!-- Mobile Layout -->
			<v-app-bar-nav-icon v-else @click="drawer = !drawer"></v-app-bar-nav-icon>
		</v-app-bar>

		<v-navigation-drawer v-model="drawer" app temporary right>
			<v-list>
				<!-- Routes -->
				<v-list-item v-for="route in routes" :key="route.id" link :to="route" color="grey darken-3">
					<v-list-item-content>
						<v-list-item-title>{{ $t(`routes.${route}`) }}</v-list-item-title>
					</v-list-item-content>
				</v-list-item>

				<v-divider></v-divider>

				<!-- Locale -->
				<v-list-group color="grey darken-3">
					<template v-slot:activator>
						<v-list-item-title>{{ locale }}</v-list-item-title>
					</template>

					<v-list-item-group color="grey darken-4" :value="locale">
						<v-list-item v-for="item in locales" :key="item.id" :value="item.text" @click="changeLocale(item.value)">
							<v-list-item-icon></v-list-item-icon>
							<v-list-item-title>{{ item.text }}</v-list-item-title>
						</v-list-item>
					</v-list-item-group>
				</v-list-group>
			</v-list>
		</v-navigation-drawer>
	</div>
</template>

<script>
	import generalMixin from '@/mixins/general'

	export default {
		mixins: [generalMixin],
		data: () => ({
			drawer: false
		}),
		computed: {
			locales() {
				return this.$store.state.locales
			},
			locale() {
				const idx = this.locales.findIndex(e => e.value === this.$i18n.locale)
				return this.locales[idx].text
			},
			routes() {
				// * Routes Names in kebabCase -> route-example
				// const routes = this.$router.options.routes.filter(e => e.name && !hidden.includes(e.name))

				// Show/Sort Routes by Route Name
				return [] //['technology','use-cases','about-us','contact'] //['solution', 'benefits', 'use-cases', 'blog', 'about-us', 'contact']
			}
		},
		methods: {
			changeLocale(locale) {
				this.$store.dispatch('changeLocale', locale)
			}
		}
	}
</script>
