export default {
	computed: {
		isMobile() {
			return this.$store.getters['isMobile']
		},
		isTablet() {
			return this.$store.getters['isTablet']
		},
		title() {
			return this.$store.getters['title']
		},
		sectionTitle() {
			return this.$store.getters['sectionTitle']
		},
		sectionSubtitle() {
			return this.$store.getters['sectionSubtitle']
		}
	}
}
