<template>
	<section>
		<v-container>
			<v-row class="my-12" align="center" justify="center">
				<v-col cols="10" md="11" class="my-4">
					<div class="text-center">
						<h2 :class="sectionTitle">{{ $t('section_features.title') }}</h2>
					</div>
				</v-col>
				<v-col cols="10" md="11">
					<v-container>
						<v-row align="start" justify="space-around">
							<!-- Cards -->
							<v-col v-for="card in cards" :key="card.id" cols="12" sm="6" lg="5" xl="3">
								<v-card flat class="transparent">
									<v-card-text class="text-center">
										<v-icon x-large color="grey darken-3">{{ card.icon }}</v-icon>
									</v-card-text>
									<v-card-title primary-title class="layout justify-center">
										<div class="subtitle-1 text-center font-weight-bold">{{ $t(card.title) }}</div>
									</v-card-title>
									<v-card-text class="text-justify">
										{{ $t(card.description) }}
									</v-card-text>
								</v-card>
							</v-col>
						</v-row>
					</v-container>
				</v-col>
			</v-row>
		</v-container>
	</section>
</template>

<script>
	import generalMixin from '@/mixins/general'

	export default {
		mixins: [generalMixin],
		data: () => ({
			cards: [
				{
					icon: 'mdi-clipboard-list',
					title: 'section_features.card_1.title',
					description: 'section_features.card_1.description'
				},
				{
					icon: 'mdi-compass',
					title: 'section_features.card_2.title',
					description: 'section_features.card_2.description'
				},
				{
					icon: 'mdi-target',
					title: 'section_features.card_3.title',
					description: 'section_features.card_3.description'
				},
				{
					icon: 'mdi-leaf',
					title: 'section_features.card_4.title',
					description: 'section_features.card_4.description'
				}
			]
		})
	}
</script>
