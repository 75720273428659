<template>
	<section>
		<v-container>
			<v-row class="my-12" align="center" justify="center">
				<v-col cols="10" md="11" class="my-4">
					<div class="text-center">
						<h2 :class="sectionTitle">{{ $t('section_team.title') }}</h2>
						<br />
						<div :class="sectionSubtitle" v-html="$t('section_team.subtitle')" />
					</div>
				</v-col>
				<v-col cols="12" lg="9">
					<v-container>
						<v-row align="start" justify="space-between">
							<!-- Cards -->
							<v-col v-for="member in team" :key="member.id" cols="12" sm="4" lg="3">
								<v-card flat class="transparent">
									<v-card-text class="text-center">
										<v-img :src="member.image" contain />
									</v-card-text>
									<v-card-text class="text-center">
										<div class="subtitle-1 font-weight-bold">{{ $t(member.name) }}</div>
										{{ $t(member.position) }}
									</v-card-text>
								</v-card>
							</v-col>
						</v-row>
					</v-container>
				</v-col>
			</v-row>
		</v-container>
	</section>
</template>

<script>
	import generalMixin from '@/mixins/general'

	export default {
		mixins: [generalMixin],
		data: () => ({
			team: [
				{
					name: 'Adrian Carrio',
					position: 'section_team.position_ceo_founder',
					image: require('@/assets/adriancarrio.jpeg')
				},
				{
					name: 'Hriday Bavle',
					position: 'section_team.position_chief_technology',
					image: require('@/assets/hridaybavle.jpg')
				},
				{
					name: 'Prof. Pascual Campoy',
					position: 'section_team.position_scientific_advisor',
					image: require('@/assets/PascualCampoy.jpg')
				}
			]
		})
	}
</script>
