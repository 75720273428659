<template>
	<v-app>
		<HeaderComponent />
		<v-main>
			<router-view />
		</v-main>
	</v-app>
</template>

<style>
	.theme--light.v-application {
		background: #f5f5f5 !important;
	}
	.cursor-pointer {
		cursor: pointer !important;
	}
</style>

<script>
	export default {
		name: 'App',
		data: () => ({
			//
		}),
		created() {
			this.$store.dispatch('loadLocale')
		}
	}
</script>
