<template>
	<section>
		<v-carousel>
			<v-carousel-item v-for="image in images" :key="image.id" class="black">
				<v-img :src="require('@/assets/Fotos/' + image)" contain height="500"></v-img>
			</v-carousel-item>
			<v-carousel-item v-for="video in videos" :key="video.id" class="black text-center">
				<iframe
					:width="isMobile ? 'auto' : '888.89'"
					height="500"
					:src="video"
					title="YouTube video player"
					frameborder="0"
					allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
					allowfullscreen
				></iframe>
			</v-carousel-item>
		</v-carousel>
	</section>
</template>

<script>
	import generalMixin from '@/mixins/general'

	export default {
		mixins: [generalMixin],
		data: () => ({
			images: [
				'DJI_M210.jpg',
				'dron_buena.jpg',
				'DSC00536.jpg',
				'DSC01979.jpg',
				'firefighting drone.jpg',
				'IMG_0925.jpg',
				'IMG_0947.jpg',
				'IMG_0980.jpg',
				'lazarus.jpg',
				'multicamera_drone.jpg',
				'new_lazarus.jpg',
				'WhatsApp Image 2020-12-13 at 15.25.12.jpeg'
			],
			videos: [
				'https://www.youtube.com/embed/fYiFeP4veyI',
				'https://www.youtube.com/embed/BZfg1Dkdoo0',
				'https://www.youtube.com/embed/ZKWXSme05zA',
				'https://www.youtube.com/embed/SBebhyyY4F8'
			]
		})
	}
</script>
