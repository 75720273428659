<template>
	<section>
		<v-container>
			<v-row class="my-12" align="center" justify="center">
				<v-col cols="10" md="11" class="my-4">
					<div class="text-center">
						<h2 :class="sectionTitle">{{ $t('section_contact.title') }}</h2>
						<br />
						<div :class="sectionSubtitle" v-html="$t('section_contact.subtitle')" />
					</div>
				</v-col>
				<v-col cols="12" lg="9">
					<v-form ref="form" lazy-validation>
						<v-container>
							<v-row align="start" justify="space-between">
								<!-- Name -->
								<v-col cols="12">
									<v-text-field :label="$t('form.name')" v-model="item.name" outlined color="grey darken-3" :rules="rules.required" required></v-text-field>
								</v-col>
								<!-- Email -->
								<v-col cols="12">
									<v-text-field :label="$t('form.email')" v-model="item.email" outlined color="grey darken-3" :rules="rules.email" required></v-text-field>
								</v-col>
								<!-- Subject -->
								<!-- <v-col cols="12">
									<v-text-field
										:label="$t('form.subject')"
										v-model="item.subject"
										outlined
										color="grey darken-3"
										:rules="rules.required"
										required
									></v-text-field>
								</v-col> -->
								<!-- Message -->
								<v-col cols="12">
									<v-textarea :label="$t('form.message')" v-model="item.message" outlined color="grey darken-3"></v-textarea>
								</v-col>
								<!-- Submit -->
								<v-col cols="12" class="text-center">
									<v-btn depressed dark x-large color="grey darken-3" class="text-capitalize" @click="submit()">
										{{ $t('form.submit') }}
									</v-btn>
								</v-col>
							</v-row>
						</v-container>
					</v-form>
				</v-col>
			</v-row>
		</v-container>
	</section>
</template>

<script>
	import generalMixin from '@/mixins/general'
	import emailjs from 'emailjs-com'

	export default {
		mixins: [generalMixin],
		data: () => ({
			item: {}
		}),
		computed: {
			rules() {
				const regex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/
				return {
					required: [v => !!v || this.$t('form.rules.required')],
					email: [v => !!v || this.$t('form.rules.required'), v => !v || regex.test(v) || this.$t('form.rules.email')]
				}
			}
		},
		methods: {
			submit() {
				if (this.$refs.form.validate()) {
					const body = {
						...this.item,
						date: this.$moment.tz('Europe/Madrid').format('LLLL')
					}
					console.log('🚀 ~ file: Home.vue ~ line 289 ~ submit ~ body', body)

					try {
						emailjs.send('service_omhyi9z', 'template_z6dpb77', body)
					} catch (error) {
						console.error('Error', error)
					} finally {
						this.item = {}
						this.$refs.form.reset()
					}
				}
			}
		}
	}
</script>
