<template>
	<div>
		<!-- Section Title -->
		<section>
			<v-img src="@/assets/scaled_color.jpg" height="600" >
				<v-overlay absolute opacity="0.4">
					<v-container>
						<v-row align="center" justify="left" class="white--text mb-16">
							<v-col cols="10" class="headline font-weight-light">
								{{ $t('section_title.subtitle') }}
							</v-col>
							<v-col cols="8" class="text-left">
								<h1 class="white--text" :class="title">{{ $t('section_title.title') }}</h1>
							</v-col>
						</v-row>
					</v-container>
				</v-overlay>
			</v-img>
		</section>

		<!-- Section Device -->
		<section>
			<v-row class="my-12" align="center" justify="center">
				<v-col cols="10" md="11" lg="8" xl="10" class="my-4">
					<div class="text-center">
						<h2 :class="sectionTitle">{{ $t('section_device.title') }}</h2>
						<br />
						<div :class="sectionSubtitle" v-html="$t('section_device.subtitle')" />
					</div>
				</v-col>
			</v-row>
		</section>

		<!-- Section Applications -->
		<section>
			<v-parallax src="@/assets/factory.webp" height="500">
				<v-overlay absolute opacity="0.6">
					<v-row align="center" justify="center" class="white--text">
						<v-col cols="10" md="11" class="text-center">
							<h2 :class="sectionTitle" >{{ $t('section_applications.title') }}</h2>
						</v-col>

						<v-col cols="10" md="11" xl="9" class="text-center">
							<div :class="sectionSubtitle" v-html="$t('section_applications.subtitle')" />
						</v-col>
					</v-row>
				</v-overlay>
			</v-parallax>
		</section>

		<!-- Section Features -->
		<FeaturesSection />

		<!-- Section Own Drone -->
		<section>
			<v-parallax src="@/assets/warehouse.webp" height="500">
				<v-overlay absolute opacity="0.6">
					<v-row align="center" justify="center" class="white--text">
						<v-col cols="10" md="11" class="text-center">
							<h2 :class="sectionTitle">{{ $t('section_own_drone.title') }}</h2>
						</v-col>

						<v-col cols="10" md="11" xl="10" class="text-center">
							<div :class="sectionSubtitle" v-html="$t('section_own_drone.subtitle')" />
						</v-col>
					</v-row>
				</v-overlay>
			</v-parallax>
		</section>

		<!-- Section Contact -->
		<ContactSection />

		<!-- Footer -->
		<v-footer color="grey darken-3">
			<v-col cols="12" class="white--text pl-8">
				Copyright © 2022 Dronomy. All rights reserved.
			</v-col>
		</v-footer>
	</div>
</template>

<script>
	import generalMixin from '@/mixins/general'

	export default {
		mixins: [generalMixin]
	}
</script>
