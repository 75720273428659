import Vue from 'vue'
import Vuex from 'vuex'
import i18n from '@/plugins/i18n'
import Vuetify from '@/plugins/vuetify'

const vuetify = Vuetify.framework

Vue.use(Vuex)

export default new Vuex.Store({
	modules: {},
	state: {
		locales: [
			{
				text: 'English',
				value: 'en'
			},
			{
				text: 'Español',
				value: 'es'
			}
		]
	},
	mutations: {},
	getters: {
		isMobile() {
			return vuetify.breakpoint.xsOnly
		},
		isTablet() {
			return vuetify.breakpoint.smAndDown
		},
		title(state, getters) {
			return getters.isMobile ? 'display-3' : 'display-4'
		},
		sectionTitle(state, getters) {
			return getters.isMobile ? 'display-1' : 'display-2'
		},
		sectionSubtitle(state, getters) {
			return getters.isMobile ? 'body-1 text-left' : 'body-1 text-center'
		}
	},
	actions: {
		loadLocale({ dispatch }) {
			let locale = 'es'
			const storageLocale = JSON.parse(localStorage.getItem('locale'))
			if (storageLocale) locale = storageLocale
			else {
				let browserLocales = window.navigator.languages === undefined ? [window.navigator.language] : window.navigator.languages
				browserLocales.map(e => e.trim().split('-')[0])
				locale = browserLocales.includes('es') ? 'es' : 'en'
			}
			dispatch('changeLocale', locale)
		},
		changeLocale(ctx, locale) {
			i18n.locale = locale
			vuetify.lang.current = locale
			localStorage.setItem('locale', JSON.stringify(locale))
		}
	}
})
