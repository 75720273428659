import { kebabCase } from 'lodash'

// const HeaderComponent = () => import(`@/views/HeaderComponent`)
const Home = () => import(`@/views/Home`)

const routes = [
	{
		path: '*',
		redirect: '/',
		components: {
			default: Home
		}
	},
	{
		path: '/',
		components: {
			default: Home
		}
	}
]

const excludeRoutes = ['home']

const requireComponent = require.context('@/views', true, /\.vue$/)

requireComponent.keys().forEach(fileName => {
	const componentName = fileName
		.split('/')
		.pop()
		.replace(/\.\w+$/, '')

	const routeName = kebabCase(componentName)

	const route = {
		name: routeName,
		path: `/${routeName}`,
		components: {
			default: () => import(`@/views/${componentName}`)
		}
	}
	// * Add Component to route
	// if (!['login', 'signup'].includes(routeName)) {
	// 	route.components.HeaderComponent = HeaderComponent
	// }

	if (!excludeRoutes.includes(route)) routes.push(route)
})

export default routes
